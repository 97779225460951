import { useStaticQuery, graphql } from "gatsby"
export const useReviewQuery = () => {

  const data = useStaticQuery(
  graphql`
  query ReviewQuery {
    allWpReview {
        edges {
          node {
            title
            reviews{
              cijfer
            }
            categories {
              nodes {
                name
              }
            }
            content
          }
        }
      }
  }
  `
)

  return data;
}

