import { useStaticQuery, graphql } from "gatsby"
export const useVestigingenQuery = () => {

  const data = useStaticQuery(
  graphql`
  query VestigingenQuery {
    allWpVestiging {
        nodes {
          seo{
            metaDesc
            title
          }
          title
          vestigingen {
            homepageHeaderAfbeeldingVestigingSpecifiek {
              mediaItemUrl
            }
            showVestiging
            vestigingAdres
            vestigingEmailadres
            vestigingPostcode
            vestigingTelefoonnummer
            contactpersoon
            fundaAankoopCijfer
            fundaVerkoopCijfer
            googleReviewCijfer
            afbeeldingContactpersoon {
              mediaItemUrl
            }
          }
        }
      }
  }
  `
)

  return data;
}

