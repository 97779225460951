import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import './cta.css'

const CtaButton = (props) => { 

    return(
            <Link to={props.target} className={`bg-red grid grid-cols-12 button group`}><span className="h-full p-4 text-white col-span-10 text-center font-bold z-10">{props.title}</span><span className={`col-span-2 flex justify-center items-center h-full bg-blue text-white z-10 px-4 ${props.hover ? props.hover : 'right'}`}><FontAwesomeIcon icon={props.icon} className="icon"></FontAwesomeIcon></span></Link>
    )
}

export default CtaButton